import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PopoverRef} from '../../core/popover/popover-ref';
import * as L from 'leaflet';
import {MatSelectChange} from "@angular/material/select";
import {TrackerStatesModel} from "../../models/tracker-states.model";
import {TrackerService} from "../../services/tracker.service";

@Component({
    selector: 'app-tracker-history-overlay',
    templateUrl: './tracker-history-overlay.component.html',
    styleUrls: ['./tracker-history-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TrackerHistoryOverlayComponent implements OnInit, AfterViewInit {

    private map: any;
    baseLayers: any;
    trackerStates: TrackerStatesModel[] = [];

    constructor(private overlayRef: PopoverRef, private trackerService: TrackerService) {

    }

    ngOnInit(): void {
        this.initTrackerStates();
    }

    ngAfterViewInit() {
        this.initMap();
    }

    private initMap(): void {
        const tiles = L.tileLayer('http://{s}.www.toolserver.org/tiles/bw-mapnik/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        });

        this.baseLayers = { OpenStreetmap: tiles };

        var historyLayer = L.layerGroup();
        var polylinePoints = [
            [37.781814, -122.404740],
            [37.781719, -122.404637],
            [37.781489, -122.404949],
            [37.780704, -122.403945],
            [37.780012, -122.404827]
        ];
        var polyline = L.polyline(polylinePoints);
        polyline.addTo(historyLayer);

       // marker.addTo(markerLayer);

        this.map = L.map('hmap', {
            center: [61.0584201, 28.1860792],
            zoom: 13,
            zoomControl: true,
            layers: [tiles],
        });
        this.map.zoomControl.setPosition('topright');

        this.map.on('moveend', () => {

        });
        const overlays = {
            History: historyLayer,
        };

        // replace it
        var baseControl = L.control.layers(this.baseLayers, overlays).addTo(this.map);
    }

    closeOverlay() {
        this.overlayRef.close();
    }

    statusChangedEvent($event: MatSelectChange) {

    }

    private initTrackerStates() {
       // this.trackerService.getStates()
    }
}
