export const environment = {
  production: true,
  backendUri: 'https://develop.hoivita.com',
  logoPath: 'assets/images/vesratio-favicon.png',
  rootUrl: '/',
  clientId: 'rPK0xWflRQznYMLKSlLqUvlqxDzYWV1h',
  uiUrl: 'https://develop.hoivita.com',
  auth0: {
    domain: 'dev-vesratio.eu.auth0.com',
    clientId: 'rPK0xWflRQznYMLKSlLqUvlqxDzYWV1h',
    audience: 'https://dev.vesrat.io',
    rulesUrl: 'https://dev.vesrat.io'
  },
  adminUiUrl: 'https://develop.hoivita.com/ovadmin',
};
